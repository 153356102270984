import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Breadcrumb from "../components/breadcrumb"
import Constrain from "../components/constrain"
import Paragraph from "../components/paragraph"
import Divider from "../components/divider"
import Layout from "../layouts/default"
import PageTitle from "../components/page-title"
import RoomNavigation from "../components/room-navigation"
import Seo from "../components/seo"
import Stack from "../components/stack"
import Trivia from "../components/trivia"
import AttributionDauerausstellung from "../content/shared/attribution-dauerausstellung"

const DauerausstellungIndexPage = () => {
  const data = useStaticQuery(graphql`
    query DauerausstellungIndexQuery {
      ogImage: file(relativePath: { eq: "og_images/dauerausstellung.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="dauerausstellung">
      <Seo
        title="Dauerausstellung"
        description="Raum für Raum können in der digitalen Dauerausstellung die Themen, Objekte und Werke der Dauerausstellung im Hölderlinturm erkundet werden. Neben Hölderlins Tübinger Studienjahren und seiner zweiten Lebenshälfte im Turm steht dabei insbesondere Hölderlins Sprachkunst und seine bis heute anhaltende Wirkung im Zentrum."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Dauerausstellung",
              link: "/dauerausstellung",
            },
          ]}
        />
        <PageTitle>Dauerausstellung</PageTitle>
        <Paragraph dropcap={true}>
          Der Tübinger Turm, in dem der Dichter Friedrich Hölderlin die zweite
          Hälfte seines Lebens verbrachte, hat sich wie kein anderer Ort in
          seine Biografie und Rezeptionsgeschichte eingeschrieben. Für Hölderlin
          war er ein Ort des Rückzugs, an dem er bis zu seinem Tod im Jahr 1843
          weiterhin Gedichte verfasste. Zugleich wurde der Turm schon zu
          Hölderlins Lebzeiten zu einem Anziehungspunkt für andere
          Schriftsteller und Bewunderer seiner Werke – und ist dies noch heute.
        </Paragraph>

        <Trivia title="Die Dauerausstellung">
          Ausgehend von Hölderlins Studienjahren am Tübinger Stift und seinen
          letzten 36 Lebensjahren im Tübinger Turm wird Hölderlins Arbeit an der
          Sprache und Metrik seiner Gedichte auf drei Etagen nachverfolgt und
          sinnlich erfahrbar gemacht. Jeder Raum greift dabei einen anderen
          Gesichtspunkt seiner Lebens-, Werk- und Wirkungsgeschichte auf.
        </Trivia>
        <Divider border={4} />
        <Stack space={[12, 24]}>
          <RoomNavigation headingElement="h2" />
          <Constrain align="left">
            <AttributionDauerausstellung />
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default DauerausstellungIndexPage
