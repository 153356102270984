import React from "react"
import { useColorMode } from "theme-ui"

import Columns from "../../components/columns"
import Column from "../../components/column"
import Text from "../../components/text"
import logoLiLand from "../../images/partner-logos/liland-bawue.svg"
import logoLiLandWhite from "../../images/partner-logos/liland-bawue-white.svg"

const AttributionDauerausstellung = (props) => {
  const [colorMode] = useColorMode()

  return (
    <Columns space={[6, 12]} collapse={[true, false]} reverse={[false, true]}>
      <Column>
        <Text>
          Die Dauerausstellung wurde zum Hölderlin-Jubiläumsjahr 2020 unter
          Federführung der Arbeitsstelle für literarische Museen, Archive und
          Gedenkstätten in Baden-Württemberg (DLA Marbach) in Zusammenarbeit mit
          der Universitätsstadt Tübingen und der Hölderlin-Gesellschaft
          konzipiert.
        </Text>
      </Column>
      <Column width="content">
        <img
          src={colorMode === "light" ? logoLiLand : logoLiLandWhite}
          alt="Logo Literaturland Baden-Württemberg"
          width="82"
          height="92"
          sx={{ width: "auto", height: "92px" }}
        />
      </Column>
    </Columns>
  )
}

export default AttributionDauerausstellung
